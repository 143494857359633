import React from "react"
// import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function testStore() {
  // const shopStuff = data.items.edges
  // console.log(shopStuff)
  return (
    <div>
      <h1>lets test the storeee</h1>
      {/* {shopStuff.map(item => (
        <div key={item.node.title}>
          <h5>{item.node.title}</h5>
          <p>{item.node.description}</p>
          <GatsbyImage image={getImage(item.node.featuredImage)} />
        </div>
      ))} */}
    </div>
  )
}

// export const query = graphql`
//   query shopQuery {
//     items: allShopifyProduct {
//       edges {
//         node {
//           title
//           description
//           featuredImage {
//             gatsbyImageData(placeholder: BLURRED)
//           }
//           variants {
//             price
//             title
//           }
//         }
//       }
//     }
//   }
// `
